<template>
  <b-card-code no-body >
    <b-button
      v-if="this.$store.state.app.user.permissions.includes('auth.add_group')"
      @click="addRole()"
      type="button"
      variant="primary"
      class="m-1 ml-auto"
      size="sm"
    >
      Add New
    </b-button>

    <b-table
      responsive
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      hover
    >
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            variant="primary"
            size="sm"
            :text="data.item.name.charAt(0)"
          />
          <span class="d-flex" style="margin-left: 5px">{{
            data.item.name
          }}</span>
        </div>
      </template>
      <template #cell(role_type)="data">
        <span v-if="data.item.role_type == 0">System</span>
        <span v-if="data.item.role_type == 1">Organization</span>
      </template>
      <template #cell(actions)="data">
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <b-dropdown-item @click="editRole(data.item.id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item @click="gotoDelete(data.item.id, data.item.name)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    />
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text> Do you really want to delete {{ roleName }} ? </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteRole()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        // { key: 'id', label: '#' },
        { key: "id", label: "ID" },
        { key: "name", label: "Name" },
        { key: "role_type", label: "Role Type" },
        { key: "actions", label: "Actions" },
      ],
      role_id: "",
      roleName: "",
      openDeleteModal: false,
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/groups",
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        //   res.data.map(function(value, key) {
        //     // console.log(res.data[key].profile.organization)
        //      if(res.data[key].profile.organization != null){
        //         res.data[key].profile.organization = res.data[key].profile.o_profile.org_name
        //      }
        //   });
        this.items = res.data;
        console.log(this.items);
        this.rows = res.data.length;
      });
    },
    addRole() {
      this.$router.push({ name: "Add Role" });
    },
    editRole(id) {
      this.$router.push({ name: "Edit Role", params: { id: id } });
    },
    gotoDelete(id, name) {
      this.openDeleteModal = true;
      this.role_id = id;
      this.roleName = name;
    },
    deleteRole() {
      // if (confirm("Do you really want to delete?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "user/groups/" + this.role_id + "/",
      };
      this.$http(options).then((res) => {
        console.log(res);
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 100px;
  overflow-y: auto;
}
</style>